import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Header from "../Subcomponent/header2";

import Pic1 from "../assets/blog3a.png";
import Footer from "../components/footer";

import { Link } from "react-router-dom";

function Blog3() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  5 Important Business Tips for New Entrepreneurs
                </h2>
                <hr className="my-5" />
                <p>
                  <strong>Posted on : </strong>
                  08/01/2020
                </p>

                <img src={Pic1} width="100%" alt="cover" />

                <br></br>
                <br></br>
                <br></br>

                <p>
                  Like every new experience, starting a business comes with its
                  own set of mixed feelings, complex decision making,
                  difficulties and stress but if you get it right from the start
                  it will be very fulfilling at the end. No matter how much
                  experience you may have in growing a company, starting one is
                  a different ball game entirely. The following practical tips
                  will answer the many questions you may have and increase the
                  chances of your startup being successful.
                </p>

                <p>
                  <ol style={{ listStyleType: "circle" }}>
                    <li>Know the basics of your business</li>
                    To start with, it is important you know all that your
                    business entails, irrespective of the specific areas that
                    interest you. You may prefer the database aspect of your
                    business or prefer to spend time expressing creativity via
                    digital marketing but someone has to see to logistics,
                    sales, payroll, customer experience and organizational
                    management as a whole. This person is you, whether you like
                    it or not, at least until you grow to the point of bringing
                    new hires on board.
                    <br></br>
                    <br></br>
                    The advantage of being involved at the beginning is that it
                    allows you to build a solid foundation and have a semblance
                    of organizational structure to scale up with. As the company
                    grows and you hire people, you will be able to fill the gaps
                    with the right skills and give your team a structure to
                    build upon.
                    <li>Have a proper legal structure</li>A time will come when
                    your business will be fully transitioned from a simple idea
                    into an exciting reality. From this point, its
                    sustainability will depend on the legal protection put in
                    from the start. Legal entities include corporations,
                    nonprofits and LLCs, and a quick research will help you
                    decide the best fit for your startup.
                    <br></br>
                    <br></br>
                    According to legal experts, legally incorporating your
                    business will provide you with certain tax benefits and
                    protect against intellectual property loss, personal
                    liability in the event of business obligations or debts.
                    <li>Network with other entrepreneurs</li>
                    <p>
                      Books are great, but there are certain lessons that you
                      will not learn if you isolate yourself from entrepreneurs,
                      especially those who have gone ahead on the path you are
                      just treading. You need to maintain a relationship with
                      other founders in your field and have regular candid
                      conversations. You can connect with business owners and
                      entrepreneurs within your personal network, local business
                      clubs, professional associations and on social media
                      platforms such as LinkedIn. The key is to put yourself out
                      there and take chances.
                      <br></br>
                      <br></br>
                      Most of these founders have seen it all and will put you
                      through if only you ask. In turn, you will learn from
                      their experiences and be better informed to make the right
                      decisions for your business.
                    </p>
                    <li>Be intentional about hiring</li>A business is as good as
                    the people in it. As your business grows, you will need to
                    recruit people to manage its varied operations. As this
                    people come on board, your capacity and output should
                    skyrocket, depending on your hiring strategy. Some venture
                    capitalists opine that a good founder should invest quality
                    time overseeing the hiring process, at least until the new
                    employees are properly onboarded.
                    <br></br>
                    <br></br>
                    To disregard the recruitment process is a set up for
                    failure, don’t fall into this trap. It is noteworthy to
                    state here that great degrees or experiences are not enough
                    basis for hiring, look beyond the façade and pay attention
                    to the soft skills, talents and abilities your startup
                    needs.
                    <li>Be open to new ideas</li>
                    To be successful as an entrepreneur is to build a profitable
                    business that yields steady returns on investment for all
                    the stakeholders involved. However, the most successful
                    founders are those who are open to the fact that their idea
                    may not be sustainable and are ready to pivot and take on
                    new ideas. This does not downplay the necessity to take
                    pride in what you’re doing but states the need to welcome
                    better opportunities that will increase your chances of
                    success.
                  </ol>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog3;
