import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../App.css";

function Detail() {
  var style = {
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  return (
    <section style={style}>
      <Container className="detail">
        <span style={{ textAlign: "center" }}>
          <h2>
            <b style={{ color: "#34343E", fontWeight: "bold" }}>
              DO YOU WANT TO CONTROL WHAT PEOPLE SEE ABOUT YOU?
            </b>{" "}
          </h2>
          <br></br>
        </span>

        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={6}>
            <lottie-player
              src="https://assets6.lottiefiles.com/packages/lf20_4savfrbs.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              loop
              autoplay
            ></lottie-player>
          </Col>

          <Col lg={6}>
            <p>
              What If I told you can control what people see about you, what
              they think about you. How much easier your life would be? How much
              more money would you make? How much easier it will be to convince
              someone that your product or service can change their life?
            </p>
            <p>
              That’s the #1 thing that the big entrepreneurs, artists,
              influencers don’t tell you when they sell something to you. They
              have that name, authority and credibility in their space. They
              don’t need to sell you, their brand already did that for them! So
              if this is something that you are interested in & would like to
              see if we can make that happen for you!
            </p>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={6}>
            <p>
              My company has been able to consistently publish people on
              publications for the past 60 months. Not only have we booked
              people on publications – but because of us they have grown their
              social media accounts with real peoples based on their niche and
              more than anything, leveraged it. People think getting press
              coverage & social media growth is the good stuff. That’s actually
              further from the truth. It’s about how you leverage it. Initially
              for a while I thought that getting my clients published would make
              sure that their message would reach the masses. However once I
              knew how the PR game works, I learned how to leverage that at the
              highest level for my clients.
            </p>
          </Col>

          <Col lg={6} className="anime">
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_U8MhmH.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              loop
              autoplay
            ></lottie-player>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={6}>
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_7Y2DQK.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "90%" }}
              loop
              autoplay
            ></lottie-player>
          </Col>

          <Col lg={6}>
            <span style={{ textAlign: "center" }}>
              <h3>
                <b style={{ fontWeight: "bold" }}>WE BOTH WANT RESULTS</b>
              </h3>
              <br></br>
              <h6>
                SO I WON'T WASTE YOUR TIME, I'VE HELPED MY CLIENTS IN MANY WAYS
                THAT INCLUDE:
              </h6>
            </span>

            <ul>
              <li style={{ fontSize: "20px" }}>
                {" "}
                &nbsp;Getting them to the point where they get{" "}
                <b style={{ fontWeight: "bold" }}>MILLIONS of views</b> on
                exposure!
              </li>
              <li style={{ fontSize: "20px" }}>
                {" "}
                &nbsp;Getting them to the point of notoriety where they{" "}
                <b style={{ fontWeight: "bold" }}>get a Google page!</b>
              </li>
              <li style={{ fontSize: "20px" }}>
                {" "}
                &nbsp;Ending up on{" "}
                <b style={{ fontWeight: "bold" }}>Famous Blogs!</b>
              </li>
              <li style={{ fontSize: "20px" }}>
                {" "}
                &nbsp;Getting them{" "}
                <b style={{ fontWeight: "bold" }}>recognized on Social Media</b>
              </li>
            </ul>
          </Col>
        </Row>

        <span style={{ textAlign: "center" }}>
          <h3>
            <b style={{ color: "#34343E", fontWeight: "bold" }}>
              THE WINNER TAKES IT ALL!
            </b>{" "}
          </h3>
          <br></br>
        </span>
        <p style={{ textAlign: "center" }}>
          What you’ve been told is a lie. You DON’T need to climb the business
          ladder. You DON’T need to spend years of your life uploading content
          to your social media. You can spend a couple of months to make sure
          that never again will you be picked last. In fact, we both know that
          on Google, most people will NEVER click beyond the first page. Most
          people end up clicking on the first link or second link and maybe even
          the third link. The company that ranks for #1 on a specific keyword
          will ALWAYS get more business easily because they are simply the first
          option. Same goes with Social Media if you have a low amount of
          Followers people will not trust your brand or business.<br></br>
          <br></br>
          How much money are you losing because you are NOT the first option.
          You are losing thousands & even MILLIONS because you are not
          considered the first option in your niche. You are not credible enough
          for people to want to deal with you. You get whatever trickles down
          from other competitors.<br></br>
          <br></br>
          So what if you could control being the 1st option? What if you could
          control your online visibility? What if you could control people’s
          perception? This is where the real strength lies. This is why I love
          what I do. For just this one simple reason.<br></br>
        </p>
      </Container>
    </section>
  );
}

export default Detail;
