import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Header from "../Subcomponent/header2";

import Footer from "../components/footer";
import { Link } from "react-router-dom";
import Pic1 from "../assets/blog11.jpg";

function Blog10() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  Breaking 3 Myths Associated With PR In 2021
                </h2>
                <hr className="my-5" />
                <p>
                  <strong>Posted on : </strong>
                  10/01/2021
                </p>

                <img src={Pic1} width="100%" alt="cover" />
                <br></br>

                <br></br>

                <p>
                  Marketing your brand in 2021 can be overwhelming. It can feel
                  like you need to be up to date on every online marketing trend
                  to stay ahead of the competition. Each year, new technologies
                  and updates change the landscape of online PR. This can lead
                  to fresh misconceptions and added confusion.
                  <br></br>Rather than taking up the burden yourself, a reliable
                  digital agency can help you get the most out of digital PR.
                  The scope of the PR industry has expanded so much in recent
                  years that myths and misconceptions have grown too. Here we
                  look at three of digital PRs biggest myths and show you the
                  truth.
                </p>

                <h4>Retainer fees is not required</h4>

                <p>
                  Several digital agencies insist that you pay a retainer fee
                  for ongoing projects. This is a myth! Rather than paying
                  unnecessary retainer fees, a project-based method is both
                  economical and efficient. The project-based method doesn’t
                  require a retainer fee and instead arranges a set goal with
                  the client for one agreed price. This method of marketing
                  completely cuts out the need to budget for retainer fees. It
                  also provides more freedom for an individual, brand, or
                  business to grow and adapt their PR activities as required.
                </p>

                <h4>PR is expensive</h4>

                <p>
                  There is a persistent myth that employing the services of a PR
                  agency is only reserved for businesses and brands that have a
                  lot of money to spend. It can be easy to feel that help from a
                  marketing agency is a luxury that you can’t afford. But
                  agencies like Growth Agency can help you kick-start your PR
                  with as little as $100. The concept of PR being expensive is
                  untrue. It is now possible for a brand to legitimately hire
                  digital PR services for a fee that won’t drain their marketing
                  budget.
                </p>

                <h4>Getting good press placement can take months </h4>

                <p>
                  Things have changed! Unlike traditional PR, digital PR is
                  fast, efficient, and affordable. As the world of online media
                  has evolved so has the world of PR and digital marketing.
                  Growth Agency can help you get featured on top-tier
                  publications in record time with a money-back guarantee.
                  <br></br>Seeing your brand featured in leading publications
                  can feel like a distant dream. The truth is that you do not
                  need to wait for several months to get a good press placement.
                  You do not need to spend heaps of money and wait endlessly;
                  this is a myth. Professional digital agencies like Growth
                  Agency can offer you guaranteed media placements with a
                  remarkably quick turn-around time.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog10;
