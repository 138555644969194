import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";

import Header from "../Subcomponent/header2";

import Pic1 from "../assets/blog1a.jpg";
import Footer from "../components/footer";

import { Link } from "react-router-dom";

function Blog1() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  If You’re Using Digital Marketing, Read This
                </h2>
                <hr className="my-5" />

                <p>
                  <strong>Posted on : </strong>
                  19/11/2019
                </p>

                <img src={Pic1} width="100%" alt="cover" />

                <br></br>
                <br></br>
                <br></br>

                <strong>
                  Digital Marketing- Using PR for marketing your brand
                </strong>
                <br></br>
                <br></br>
                <p>
                  Being a brand or having a brand can be successful only when
                  you manage your public relations with those who have made you
                  or your brand a success i.e. public. A regular communication
                  with the public helps you to know where the brand is going,
                  how much it is liked or disliked and also why it is disliked
                  and what needs to be changed. Same goes for a person who is a
                  brand.
                </p>
                <p>
                  Managing a brand or being a brand isn’t easy in itself. You
                  cannot manage your public relations all by yourself as it
                  involves several aspects and several platforms. A professional
                  PR service organization can be very helpful for you here.
                </p>
                <strong>Branding and Personal Branding</strong>
                <br></br>
                <br></br>

                <p>
                  Branding a product or service and branding a person is very
                  different and you must know the difference. Whether you want
                  PR for a product or for branding yourself, you need a
                  professional who understands the difference between both and
                  can make suitable digital marketing strategies which suits
                  your purpose perfectly.
                </p>

                <p>
                  When it comes to a brand, you need to keep people interested
                  in it. But when it comes to personal branding, there are some
                  qualities which the public look for.
                  <ol style={{ listStyleType: "circle" }}>
                    <li>
                      First, they want you to be genuine as they are rational
                      and can see through your act.
                    </li>
                    <li>
                      Second, if you don’t stick to what you said, they won’t
                      stick with you. Be consistent with your message.
                    </li>
                    <li>
                      There are some people today whose name is a brand in
                      itself. They haven’t reached that level overnight. They
                      have worked hard and have faced failure. So, be ready to
                      fail.
                    </li>
                    <li>
                      When you are a brand, you influence a lot of people. You
                      do not want to share any negativity. So, be positive and
                      spread positivity only.
                    </li>
                  </ol>
                  In order to make you or your brand a success, hiring a
                  professional PR service is imperative which keeps in mind the
                  above points and make a successful campaign for your branding.
                </p>

                <strong>Role of digital marketing in PR</strong>
                <br></br>
                <br></br>

                <p>
                  The increased use of social media platforms has provided you
                  with several digital platforms which can prove to be very
                  successful for branding or personal branding. Instagram,
                  Facebook, Twitter, Snapchat, LinkedIn, are just a few examples
                  of the social media websites which have proved themselves very
                  useful. Today, digital marketing has become the key to become
                  a successful brand. Numerous brands use it today only because
                  it is successful. The use of a carefully designed and crafted
                  digital marketing strategy can go a long way in maintaining
                  good public relations. The second thing is, the number of
                  social media users is increasing and will only increase in the
                  future which ensures that it is going to be a long term
                  marketing tool. No doubt, that the traditional methods are
                  very good but digital marketing with the rapid social media
                  growth has changed the face of marketing.
                </p>

                <strong>Final Words</strong>
                <br></br>
                <br></br>

                <p>
                  You can see a lot of examples in the market who have succeeded
                  with the right usage of social media marketing. And why not,
                  it has become a great tool for marketing. For establishing
                  good public relations, the use of various social media
                  platforms is a sure shot strategy in the present times. Don’t
                  wait long. Hire a PR firm today.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog1;
