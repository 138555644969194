import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Header from "../Subcomponent/header2";

import Pic1 from "../assets/blog10a.png";
import Pic2 from "../assets/blog10b.png";
import Pic3 from "../assets/blog10c.png";
import Pic4 from "../assets/blog10d.png";
import Pic5 from "../assets/blog10e.png";

import Footer from "../components/footer";
import { Link } from "react-router-dom";

function Blog10() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  Everything You Need to Know About Google Knowledge Panel Today
                </h2>
                <hr className="my-5" />
                <p>
                  <strong>Posted on : </strong>
                  05/11/2020
                </p>
                <h4>What is Google Knowledge Panel?</h4>
                <div style={{ textAlign: "center" }}>
                  <img src={Pic1} width="50%" height="60%" alt="cover" />
                </div>
                <br></br>
                <br></br>
                <br></br>
                <b>
                  All of us has come across this block on the top right corner
                  of the search engine result page (SERP). Some know what it’s
                  called while some don’t. For those of you who do not know,
                  this is called the Google Knowledge Panel.
                </b>
                <br></br>
                <br></br>The job of the Knowledge Panel is to provide reliable
                rich information of entities for the users. Typically well-known
                entities. The information are obtained from trusted sources, in
                most cases, Wikipedia. Here is another example of the Knowledge
                Panel:
                <div style={{ textAlign: "center" }}>
                  <img src={Pic2} width="50%" height="80%" alt="cover" />
                </div>
                <p>
                  As seen above, the type of information in the Knowledge Panel
                  is different for every entity. It depends on the information
                  obtained from verified entities/ official representatives,
                  schema markup on a website, Wikidata and also Google Knowledge
                  Graph.
                </p>
                <h4>Benefits of Google Knowledge Panel</h4>
                <h6>For businesses</h6>
                <ol>
                  <li>
                    <b>Increases Visibility</b>
                    <br></br>One very obvious benefit is that it helps the
                    featured (people, businesses, events, and others) to
                    increase visibility.
                    <br></br>On a computer screen, the panel is very likely the
                    first thing people will see, considering where it’s situated
                    and its size. As for mobile phones, it surely is the first
                    thing people see.
                  </li>

                  <div style={{ textAlign: "center" }}>
                    <img src={Pic3} width="50%" height="80%" alt="cover" />
                  </div>

                  <li>
                    <b>Establishes Authority</b>
                    <br></br>Being in the Knowledge Panel will make the featured
                    entity appear established. All of us tend to believe Google.
                    Many people perceived the entities are authentic because of
                    Google putting them in the panel. It is also not easy to get
                    a panel on SERP.
                  </li>

                  <li>
                    <b>Higher User Engagement</b>
                    <br></br>When your business is featured in a Knowledge
                    Panel, you potentially gain more engagement. You’ve already
                    gained visibility, but the knowledge panel includes your
                    website and social media profile.
                    <br></br>In this social media era, every information is
                    updated online. If the users are interested to learn more
                    about the entity, they can engage with them by clicking on
                    the social media profiles in the knowledge panel.
                  </li>

                  <div style={{ textAlign: "center" }}>
                    <img src={Pic4} width="50%" height="80%" alt="cover" />
                  </div>
                </ol>
                <b>For users.</b>
                <br></br>
                <ol>
                  <li>
                    <b>Quick and “Rich” Results</b>
                    <br></br>
                  </li>
                  <p>
                    In the past, after you’ve entered a search query, you will
                    just be shown a list of websites. Just a plain webpage. Now,
                    users get plenty of useful and great quality information
                    which includes{" "}
                    <b>
                      images, social media profiles, nice-to-know facts, and
                      related entities.
                    </b>
                    <br></br>
                    <br></br>Another good thing is that the data in the
                    knowledge graph is so good that sometimes you do not need to
                    click on any websites and leave the page. It saves users
                    time, which is very much appreciated by the users.
                  </p>
                </ol>
                <div style={{ textAlign: "center" }}>
                  <img src={Pic5} width="50%" height="80%" alt="cover" />
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog10;
