import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets3.lottiefiles.com/packages/lf20_8wAhbq.json"
                background="transparent"
                speed="1"
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                Google Knowledge Panel Package
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <h2 style={{ fontWeight: "bold", color: "goldenrod" }}>
                <sup>$</sup>300 - 600
              </h2>

              <p>
                Have you ever wanted to be verified on Google? Now is your
                chance…
                <br></br>This product is not for everyone because before
                purchasing this product you either need to have 15+ notable
                articles already published or you can choose one of our other
                press packages before going for a Google Knowledge Panel.
              </p>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>
                  Why is a Google Knowledge Panel so Important?
                </b>{" "}
              </h3>
            </span>

            <p>
              A Google Knowledge Panel is so important because it’s the first
              thing that anyone sees when they search your name on Google for
              the rest of your life. This panel will include a picture of
              yourself, a short bio, a date of birth, businesses owned and links
              to your main social media platforms.
            </p>
          </MDBCardBody>
        </MDBCard>
      </Container>
    </section>
  );
}

export default OfferBox;
