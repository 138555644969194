import React from "react";
import { GrInstagram, GrFacebook } from "react-icons/gr";
import { Link } from "react-router-dom";

function Footer() {
  var style = {
    backgroundColor: "#34343E",
  };
  return (
    <div style={style}>
      <footer>
        <div className="top_header">
          <section>
            <span>
              <i className="fa fa-map-marker"></i>
            </span>
            <span>
              156, Acharyya Jagadish Bose Road, Entally Market, Kolkata- 700014,
              India
            </span>
          </section>

          <section>
            <span>
              <i className="fa fa-envelope"></i>
            </span>
            <span>contact@growthagency.in</span>
          </section>
        </div>
        <span className="border-shape"></span>
        <div className="bottom_content">
          <section>
            <a
              href="https://www.facebook.com/growthagency.in"
              rel="noopener noreferrer"
              target="_blank"
            >
              <GrFacebook style={{ fontSize: "25px" }} />
            </a>
            <a
              href="https://www.instagram.com/growthagency.in"
              rel="noopener noreferrer"
              target="_blank"
            >
              <GrInstagram style={{ fontSize: "25px" }} />
            </a>
          </section>
          <section>
            <Link to="/">Home</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/privacypolicy">Privacy Policy</Link>
            {/* <Link to="/termsandcondition">Terms and Condition</Link>
            <Link to="/refundpolicy">Refund & Cancellation Policy</Link> */}
          </section>
        </div>
        <div className="copyright">
          Copyright &copy; {new Date().getFullYear()}, All Right Reserved{" "}
          <a href="https://www.growthagency.in">Growth Agency</a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
