import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import "./offerStyles.css";

// import {Nav.Link} from 'react-router-dom';

function Offer() {
  var style = {
    backgroundColor: "#34343E",
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  var border = {
    borderBottom: "6px solid goldenrod",
  };

  return (
    <section id="services" style={style}>
      <h1 style={{ color: "white", textAlign: "center" }}>
        <b>Services</b>{" "}
      </h1>
      <br></br>
      <br></br>
      <Container>
        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/instagram-follower">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/fluent/144/000000/instagram-new.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Instagram Followers Package
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>

          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/instagram-engagement">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/fluent/144/000000/instagram-new.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Instagram Engagement Package
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>

          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/press-publication">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/emoji/128/000000/newspaper-emoji.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Press Publication Package
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/google-knowledge-panel">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/color/144/000000/google-logo.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Google Knowledge Panel Package
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/DMleadgeneration">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/dusk/144/000000/forward-message.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    DM Lead Generation
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>

          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/coldemail">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/fluent/144/000000/email-open.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">Cold Email</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/youtube">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/fluent/144/000000/youtube-play.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Youtube Packages
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col>
        </Row>

        {/* <Col lg={4} md={4} sm={12} xs={12} className="col-card">
            <Nav.Link href="/spotify">
              <MDBCard className="offerCards" style={{ borderRadius: "5%" }}>
                <MDBCardBody style={border} className="offerCards">
                  <span className="card-iconx">
                    <img
                      style={{ paddingBottom: "40px" }}
                      src="https://img.icons8.com/fluent/144/000000/spotify.png"
                      alt="icon"
                    />
                  </span>

                  <MDBCardTitle className="offername">
                    Spotify Package
                  </MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </Nav.Link>
          </Col> */}
      </Container>
    </section>
  );
}

export default Offer;
