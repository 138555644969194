import React from "react";
import Header2 from "../../Subcomponent/header2.js";
import "./offerStyles.css";

import OfferBox5 from "../../Subcomponent/offerbox5.js";
import Footer from "../footer";

function Offer1() {
  return (
    <section>
      <Header2 />

      <OfferBox5 />
      <div style={{ paddingTop: "40px" }}>
        <Footer />
      </div>
    </section>
  );
}

export default Offer1;
