import React from "react";
import Header from "./Subcomponent/header2";
import Footer from "./components/footer";
import { Container } from "react-bootstrap";

export default function PrivacyPolicy() {
  return (
    <div id="privacypolicy">
      <Header />
      <Container style={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <h1 style={{ textAlign: "center" }}>Our Privacy Policy</h1>

        <p style={{ paddingTop: "40px" }}>
          At www.growthagency.in, Accessible From Https://www.growthagency.in/,
          One Of Our Main Priorities Is The Privacy Of Our Visitors. This
          Privacy Policy Document Contains Types Of Information That Is
          Collected And Recorded By www.growthagency.in And How We Use It.
          <br></br>
          <br></br>
          If You Have Additional Questions Or Require More Information About Our
          Privacy Policy, Do Not Hesitate To Contact Us.
          <br></br>
          <br></br>
          This Privacy Policy Applies Only To Our Online Activities And Is Valid
          For Visitors To Our Website With Regards To The Information That They
          Shared And/Or Collect In www.growthagency.in. This Policy Is Not
          Applicable To Any Information Collected Offline Or Via Channels Other
          Than This Website.
          <br></br>
          <br></br>
          Consent – By Using Our Website, You Hereby Consent To Our Privacy
          Policy And Agree To Its Terms. Information We Collect – The Personal
          Information That You Are Asked To Provide, And The Reasons Why You Are
          Asked To Provide It, Will Be Made Clear To You At The Point We Ask You
          To Provide Your Personal Information. If You Contact Us Directly, We
          May Receive Additional Information About You Such As Your Name, Email
          Address, Phone Number, The Contents Of The Message And/Or Attachments
          You May Send Us, And Any Other Information You May Choose To Provide.
          <br></br>
          <br></br>
          When You Register For An Account, We May Ask For Your Contact
          Information, Including Items Such As Name, Company Name, Address,
          Email Address, And Telephone Number. How We Use Your Information – We
          Use The Information We Collect In Various Ways, Including To: Provide,
          Operate, And Maintain Our Website Improve, Personalize, And Expand Our
          Website Understand And Analyze How You Use Our Website Develop New
          Products, Services, Features, And Functionality Communicate With You,
          Either Directly Or Through One Of Our Partners, Including For Customer
          Service, To Provide You With Updates And Other Information Relating To
          The Website , And For Marketing And Promotional Purposes Send You
          Emails, Find And Prevent Fraudlog Files www.growthagency.in Follows A
          Standard Procedure Of Using Log Files.
          <br></br>
          <br></br>
          These Files Log Visitors When They Visit Websites. All Hosting
          Companies Do This And A Part Of Hosting Services’ Analytics. The
          Information Collected By Log Files Include Internet Protocol (Ip)
          Addresses, Browser Type, Internet Service Provider (Isp), Date And
          Time Stamp, Referring/Exit Pages, And Possibly The Number Of Clicks.
          These Are Not Linked to Any Information That Is Personally
          Identifiable. The Purpose Of The Information Is For Analyzing Trends,
          Administering The Site, Tracking Users’ Movement On The Website, And
          Gathering Demographic Information. Our Privacy Policy Was Created With
          The Help Of The Privacy Policy Generator And The Online Privacy Policy
          Generator.
          <br></br>
          <br></br>
          Advertising Partners Privacy Policies You May Consult This List To
          Find The Privacy Policy For Each Of The Advertising Partners Of
          www.growthagency.in. Third-Party Ad Servers Or Ad Networks Uses
          Technologies Like Cookies, Javascript, Or Web Beacons That Are Used In
          Their Respective Advertisements And Links That Appear On
          www.growthagency.in, Which Are Sent Directly To Users’ Browser. They
          Automatically Receive Your Ip Address When This Occurs. These
          Technologies Are Used To Measure The Effectiveness Of Their
          Advertising Campaigns And/Or To Personalize The Advertising Content
          That You See On Websites That You Visit. Note That www.growthagency.in
          Has No Access To Or Control Over These Cookies That Are Used By
          Third-Party Advertisers.
          <br></br>
          <br></br>
          Third Party Privacy Policies www.growthagency.in’s Privacy Policy Does
          Not Apply To Other Advertisers Or Websites. Thus, We Are Advising You
          To Consult The Respective Privacy Policies Of These Third-Party Ad
          Servers For More Detailed Information. It May Include Their Practices
          And Instructions About How To Opt-Out Of Certain Options. You Can
          Choose To Disable Cookies Through Your Individual Browser Options. To
          Know More Detailed Information About Cookie Management With Specific
          Web Browsers, It Can Be Found At The Browsers’ Respective Websites.
          Ccpa Privacy Rights (Do Not Sell My Personal Information) Under The
          Ccpa, Among Other Rights, California Consumers Have The Right To:
          Request That A Business That Collects A Consumer’s Personal Data
          Disclose The Categories And Specific Pieces Of Personal Data That A
          Business Has Collected About Consumers. Request That A Business Delete
          Any Personal Data About The Consumer That A Business Has Collected.
          Request That A Business That Sells A Consumer’s Personal Data, Not
          Sell The Consumer’s Personal Data. If You Make A Request, We Have One
          Month To Respond To You. If You Would Like To Exercise Any Of These
          Rights, Please Contact Us. Gdpr Data Protection Rights We Would Like
          To Make Sure You Are Fully Aware Of All Of Your Data Protection
          Rights.
          <br></br>
          <br></br>
          Every User Is Entitled To The Following: The Right To Access – You
          Have The Right To Request Copies Of Your Personal Data. We May Charge
          You A Small Fee For This Service. The Right To Rectification – You
          Have The Right To Request That We Correct Any Information You Believe
          Is Inaccurate. You Also Have The Right To Request That We Complete The
          Information You Believe Is Incomplete. The Right To Erasure – You Have
          The Right To Request That We Erase Your Personal Data, Under Certain
          Conditions. The Right To Restrict Processing – You Have The Right To
          Request That We Restrict The Processing Of Your Personal Data, Under
          Certain Conditions.
          <br></br>
          <br></br>
          The Right To Object To Processing – You Have The Right To Object To
          Our Processing Of Your Personal Data, Under Certain Conditions. The
          Right To Data Portability – You Have The Right To Request That We
          Transfer The Data That We Have Collected To Another Organization, Or
          Directly To You, Under Certain Conditions. If You Make A Request, We
          Have One Month To Respond To You. If You Would Like To Exercise Any Of
          These Rights, Please Contact Us. Children’s Information Another Part
          Of Our Priority Is Adding Protection For Children While Using The
          Internet. We Encourage Parents And Guardians To Observe, Participate
          In, And/Or Monitor And Guide Their Online Activity.
          www.growthagency.in Does Not Knowingly Collect Any Personal
          Identifiable Information From Children Under The Age Of 13. If You
          Think That Your Child Provided This Kind Of Information On Our
          Website, We Strongly Encourage You To Contact Us Immediately And We
          Will Do Our Best Efforts To Promptly Remove Such Information From Our
          Records
        </p>
      </Container>
      <Footer />
    </div>
  );
}
