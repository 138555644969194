import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";

import logo1 from "../assets/logo/updated/adp.png";
import logo2 from "../assets/logo/updated/ab7.png";
import logo3 from "../assets/logo/updated/ap.png";
import logo4 from "../assets/logo/updated/business-deccan.png";
import logo5 from "../assets/logo/updated/california-herald.png";
import logo6 from "../assets/logo/updated/dailyhunt.png";
import logo7 from "../assets/logo/updated/digital.png";
import logo8 from "../assets/logo/updated/disrupt.png";
import logo9 from "../assets/logo/updated/entrepre.png";
import logo10 from "../assets/logo/updated/forbes.png";
import logo11 from "../assets/logo/updated/fox34.png";
import logo12 from "../assets/logo/updated/hindustan-times.png";
import logo13 from "../assets/logo/updated/ibmw.png";
import logo14 from "../assets/logo/updated/IBTimes.png";
import logo15 from "../assets/logo/updated/influ.png";
import logo16 from "../assets/logo/updated/kivo.png";
import logo17 from "../assets/logo/updated/lately.png";
import logo18 from "../assets/logo/updated/market.png";
import logo19 from "../assets/logo/updated/medium.png";
import logo20 from "../assets/logo/updated/nbc.png";
import logo21 from "../assets/logo/updated/neuz.png";
import logo22 from "../assets/logo/updated/theindiansaga.png";
import logo23 from "../assets/logo/updated/thestatesman.png";
import logo24 from "../assets/logo/updated/verna.png";
import logo25 from "../assets/logo/updated/yahoo.png";
import logo26 from "../assets/logo/updated/25hours.png";
import logo27 from "../assets/logo/updated/ustimes.png";
import logo28 from "../assets/logo/updated/thrive-global.png";

function Cover6() {
  var style = {
    width: "100%",
    marginTop: "50px",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  var para = {
    fontFamily: "Raleway, sans-serif",
    fontSize: "20px",
    color: "#9C9CA8",
    textAlign: "center",
  };

  return (
    <section id="press" style={style}>
      <Container>
        <h1 style={{ color: "#34343E", textAlign: "center" }}>
          <b>Get Featured in the Press </b>
        </h1>
        <br></br>
        <br></br>
        <p style={para}>
          With our connections to press, we can get you featured in some of the
          top articles in the world. Publications like Forbes, Entrepreneur, New
          York Times, Wall Street Journal, Fox Business, Tech Crunch, Wired,
          TED, etc. What kind of credibility would this tie to your
          brand/company?
        </p>
        <br></br>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo1} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo2} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo3} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo4} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo5} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo6} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo7} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo8} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo9} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo10} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo11} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo12} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo13} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo14} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo15} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo16} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo17} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo18} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo19} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo20} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo21} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo22} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo23} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo24} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo25} width="100%" height="100%" alt="logo" />
          </Col>

          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo26} width="100%" height="100%" alt="logo" />
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo27} width="100%" height="100%" alt="logo" />
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <img src={logo28} width="100%" height="100%" alt="logo" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cover6;
