import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  const [optionValue, changeOptionValue] = useState("Bronze");

  const handleChange = (e) => changeOptionValue(e.target.value);

  return (
    <section>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets10.lottiefiles.com/packages/lf20_3s913D.json"
                background="transparent"
                speed="1"
                style={{ width: "100%", height: "100%" }}
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                Instagram Engagement Package
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                We offer our Instagram Engagement packages to give you the edge
                on the competition. In today’s market everybody faces engagement
                & exposure issues on Instagram. The current algorithm suppresses
                account organic exposure and your profile is hidden! Our
                engagement services are “cheat codes” to increased engagement &
                exposure on Instagram. We leverage automated engagement chats to
                drive traffic & post engagement to our client’s accounts. Check
                out our service offerings below and get ready to take your
                engagement to the next level!
              </p>

              {/* Dropdown begins*/}
              <h2 style={{ color: "goldenrod" }}>Price on request</h2>
              <select value={optionValue} onChange={handleChange}>
                <option value="Bronze">Bronze Package</option>
                <option value="Silver">Silver Package</option>
                <option value="Gold">Gold Package</option>
                <option value="Diamond">Diamond Package</option>
              </select>

              {/* Dropdown ends */}

              <div>
                {optionValue === "Diamond" && (
                  <div>
                    <ul>
                      <li>10 posts trending a month</li>
                      <li>12k-18k Impressions per post</li>
                      <li>5000-7000 Likes per post</li>
                      <li>30k-50k Views on videos</li>
                      <li>60-70 Comments per post</li>
                      <li>250-300 Saves</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Gold" && (
                  <div>
                    <ul>
                      <li>10 posts trending a month</li>
                      <li>5k-7k Impressions per post</li>
                      <li>2000-3000 Likes per post</li>
                      <li>15k-20k Views on videos</li>
                      <li>35-45 Comments per post</li>
                      <li>100-150 Saves</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Silver" && (
                  <div>
                    <ul>
                      <li>10 posts trending a month</li>
                      <li>2k-3k Impressions per post</li>
                      <li>800-1200 Likes per post</li>
                      <li>3k-5k Views on videos</li>
                      <li>15-20 Comments per post</li>
                      <li>50-100 Saves</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Bronze" && (
                  <div>
                    <ul>
                      <li>1 post trending a month</li>
                      <li>2k-3k Impressions per post</li>
                      <li>800-1200 Likes per post</li>
                      <li>3k-5k Views on videos</li>
                      <li>15-20 Comments per post</li>
                      <li>30-50 Saves</li>
                    </ul>
                  </div>
                )}
              </div>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>How it works?</b>{" "}
              </h3>
            </span>

            <p>
              The process is simple, your account will be inserted into our
              automated engagement groups. All group members (unpaid) are
              promoted to engage with post links in order to receive engagement
              as well. Your new posts will be inserted into our groups and
              places at the top of the lists. There is no participation required
              on your end to receive engagement. It’s important to note that
              engagement will be delivered on average within 24-48 hours of you
              posting onto Instagram. All packages are planned for up to (10)
              posts a month. If you require more posts or more likes per post
              for your personal / business page, schedule a call with our team
              and we can create a custom package & custom payment link for
              purchase & renewal.
            </p>
          </MDBCardBody>
        </MDBCard>
      </Container>
    </section>
  );
}

export default OfferBox;
