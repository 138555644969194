import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsTag } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { RiNewspaperLine } from "react-icons/ri";
import { BsGraphUp } from "react-icons/bs";
import { MDBBtn } from "mdbreact";
import "../App.css";

function Cover2() {
  return (
    <section id="whoweare">
      <Container className="cover2">
        <h1>
          <b style={{ color: "#34343E" }}>Who We Are </b>{" "}
        </h1>
        <br></br>

        <p className="cover2-para">
          Whether you and your company are looking for{" "}
          <b style={{ color: "#34343E" }}>placements in press articles</b> like
          Forbes, Market Watch, Yahoo, etc., growth in{" "}
          <b style={{ color: "#34343E" }}>Instagram, Facebook, Youtube</b> or
          you’re looking for <b style={{ color: "#34343E" }}>AR Filters</b> we
          want to be your bridge between the vision you have, and the best
          results possible. We are Growth Agency, and our aim, is to be your
          long-term Marketing arm that provides you with the latest & greatest
          products, opportunities, and credibility possible, to bring your
          product and service to more customers.
          <br></br>
          <br></br>
          <b style={{ color: "#34343E" }}>
            With a combined 5+ years in the game, our top priority has become
            customer service, and more importantly, results. We are your one
            stop shop for anything digital.
          </b>
        </p>
        <br></br>
        <br></br>
      </Container>

      <Container>
        <Row className="buttons-small">
          <Col xs={12} sm={12} md={12} lg={3} style={{ padding: "10px" }}>
            <MDBBtn
              style={{ fontSize: "18px" }}
              color=" #eae8e"
              className="btn-hover-4 color-main"
            >
              <BsTag /> BRANDING
            </MDBBtn>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} style={{ padding: "10px" }}>
            <MDBBtn
              style={{ fontSize: "18px" }}
              color=" #eae8e"
              className="btn-hover-4 color-main"
            >
              <FiInstagram /> IG GROWTH
            </MDBBtn>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} style={{ padding: "10px" }}>
            <MDBBtn
              style={{ fontSize: "18px" }}
              color=" #eae8e"
              className="btn-hover-4 color-main"
            >
              <BsGraphUp /> SMM
            </MDBBtn>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3} style={{ padding: "10px" }}>
            <MDBBtn
              style={{ fontSize: "18px" }}
              color="#eae8e"
              className="btn-hover-4 color-main"
            >
              <RiNewspaperLine /> PRESS
            </MDBBtn>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cover2;
