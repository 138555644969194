import React from "react";
import { Container } from "react-bootstrap";
import "../App.css";
import WarningIcon from "../assets/warningIcon.png";
import { useWindowSize } from "../customHook";

function WarningSection() {
  const [width] = useWindowSize();
  var style = {
    width: "100%",
    marginTop: "50px",
    paddingTop: "40px",
    paddingBottom: "40px",
  };

  var para = {
    fontFamily: "Raleway, sans-serif",
    fontSize: "20px",
    color: "#9C9CA8",
    textAlign: "center",
  };

  return (
    <section id="warningSection" style={style}>
      <Container>
        {width >= 499 ? (
          <h1
            style={{
              color: "#34343E",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={WarningIcon} alt="warning" /> &nbsp;{" "}
            <b>Extreme Warning</b> &nbsp;
            <img src={WarningIcon} alt="warning" />
          </h1>
        ) : (
          <h4
            style={{
              color: "#34343E",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={WarningIcon} alt="warning" /> &nbsp;{" "}
            <b>Extreme Warning</b> &nbsp;
            <img src={WarningIcon} alt="warning" />
          </h4>
        )}

        <br></br>
        <br></br>
        <p style={para}>
          We are not responsible for an increased tax burden of using Growth’s
          services and bringing more revenue into your business.
        </p>
        <br></br>
      </Container>
    </section>
  );
}

export default WarningSection;
