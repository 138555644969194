import React from "react";
import { Player } from "video-react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";

const TestimonialsMultiPage = () => {
  var style = {
    backgroundColor: "#34343E",
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
  };
  return (
    <section id="Testimonial" style={style}>
      <h1 style={{ color: "white", textAlign: "center" }}>
        <b>What our Client says?</b>{" "}
      </h1>
      <br></br>
      <br></br>
      <Container>
        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182733/Testimonial/v1_y1pg6y.mp4" />
            </Player>
          </Col>

          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182751/Testimonial/v3_lpdd2e.mp4" />
            </Player>
          </Col>

          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182754/Testimonial/v5_bnztlw.mp4" />
            </Player>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "40px" }}>
          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182754/Testimonial/v4_shcd9b.mp4" />
            </Player>
          </Col>

          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182754/Testimonial/v5_cbrutx.mp4" />
            </Player>
          </Col>

          <Col lg={4} md={12} sm={12} xs={12} className="col-card-testimonial ">
            <Player fluid={false} width={300} height={300}>
              <source src="https://res.cloudinary.com/growth-agency/video/upload/v1610182747/Testimonial/v2_cqzsi9.mp4" />
            </Player>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TestimonialsMultiPage;
