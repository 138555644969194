import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "../App.css";
import Banner1 from "../assets/banner1.png";
import { Link } from "react-scroll";

import HeaderExtended from "./headerExtended.js";

function Header() {
  var custom = {
    color: "white",
    display: "flex",
  };
  return (
    <div>
      <Container>
        <Navbar bg="transparent" expand="lg">
          <Navbar.Toggle id="nav-icon1" aria-controls="basic-navbar-nav">
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto nav-link">
              <Nav.Link style={custom}>
                <Link to="whoweare" smooth={true} duration={2000}>
                  {" "}
                  Who We Are{" "}
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
              <Nav.Link style={custom}>
                <Link to="ourguarantee" smooth={true} duration={2000}>
                  {" "}
                  Client's Result
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
              <Nav.Link style={custom}>
                <Link to="services" smooth={true} duration={2000}>
                  {" "}
                  Services{" "}
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
              <Nav.Link style={custom}>
                <Link to="press" smooth={true} duration={2000}>
                  {" "}
                  Get Featured
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
              <Nav.Link style={custom}>
                <Link to="press-article" smooth={true} duration={2000}>
                  {" "}
                  Press
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
              <HeaderExtended />
              &nbsp;&nbsp;
              <Nav.Link style={custom}>
                <Link to="bookacall" smooth={true} duration={2000}>
                  {" "}
                  Book a call
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>

      <div className="banner1">
        <img src={Banner1} width="632px" height="559px" alt="banner1" />
      </div>
    </div>
  );
}

export default Header;
