import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ar1 from "../assets/ar1.jpg";
import ar2 from "../assets/ar2.jfif";
import ar3 from "../assets/ar3.jpg";
import ar4 from "../assets/ar4.jpg";
import ar5 from "../assets/ar5.jpeg";
import ar6 from "../assets/ar6.jpeg";

import logo1 from "../assets/Forbes.jpg";
import logo2 from "../assets/news.png";
import logo3 from "../assets/Entrepreneur.jpg";
import logo4 from "../assets/yahoo.png";
import logo5 from "../assets/futureshark.png";
import logo6 from "../assets/celebmix.png";

function Press() {
  var style = {
    backgroundColor: "#EAE8E8",
    width: "100%",
    marginTop: "50px",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  return (
    <div style={style} id="press-article">
      <Container fluid>
        <h1 style={{ textAlign: "center" }}>
          <b style={{ color: "#34343E" }}>Press Articles</b>{" "}
        </h1>
        <h4 style={{ color: "goldenrod", textAlign: "center" }}>
          How Our Clients Have Been Featured in the Press
        </h4>
      </Container>

      <Container fluid>
        <Row>
          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar1} height="250px" alt="ar1" />
              <Card.Body>
                <br></br>
                <span className="press-logo1">
                  <img src={logo1} width="100px" height="30px" alt="l1" />
                </span>
                <Card.Title className="press-title">
                  How Alex Boro Disrupted Sneaker Reselling And Created Boro Inc
                </Card.Title>

                <footer>
                  <a
                    style={{ color: "goldenrod" }}
                    href="https://www.forbes.com/sites/josephdeacetis/2020/03/18/how-alex-boro-disrupted-sneaker-reselling-and-created-boro-inc/#4d5f435a55ea"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Read More
                  </a>
                </footer>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar2} height="250px" alt="ar2" />
              <Card.Body>
                <br></br>
                <span className="press-logo2">
                  <img src={logo2} alt="l1" />
                </span>
                <Card.Title className="press-title">
                  Social Media Mansion: Million-dollar company’s secret to
                  success
                </Card.Title>

                <a
                  style={{ color: "goldenrod" }}
                  href="https://www.news.com.au/finance/work/careers/social-media-mansion-milliondollar-companys-secret-to-success/news-story/5a552ac396a92f76e3a8fe5cecc0e68b"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read More
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar3} height="250px" alt="ar1" />
              <Card.Body>
                <br></br>

                <span className="press-logo3">
                  <img src={logo3} width="100%" height="100%" alt="l1" />
                </span>

                <Card.Title className="press-title">
                  Three Acclaimed Performers Share the Pivots They're Making to
                  ...
                </Card.Title>

                <a
                  style={{ color: "goldenrod" }}
                  href="https://www.entrepreneur.com/article/352958"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read More
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar4} height="250px" alt="ar1" />
              <Card.Body>
                <br></br>

                <span className="press-logo4">
                  <img src={logo4} width="100%" height="100%" alt="l1" />
                </span>

                <Card.Title className="press-title">
                  Meet DJ Omega, bringing the world of art and hip hop together
                  ..
                </Card.Title>

                <a
                  style={{ color: "goldenrod" }}
                  href="https://in.news.yahoo.com/meet-dj-omega-bringing-world-104548996.html"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read More
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar5} height="250px" alt="ar1" />
              <Card.Body>
                <br></br>
                <span className="press-logo5">
                  <img src={logo5} width="100%" height="100%" alt="l1" />
                </span>
                <Card.Title className="press-title">
                  Richard Orlinski master to create unconventional pieces of art
                  and ...
                </Card.Title>

                <a
                  style={{ color: "goldenrod" }}
                  href="https://futuresharks.com/to-create-unconventional-pieces-of-art-one-needs-to-be-an-unorthodox-and-innovative-creator/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read More
                </a>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={4} lg={6} md={6} sm={12} xs={12} className="press-col">
            <Card className="press-card">
              <Card.Img src={ar6} height="250px" alt="ar1" />
              <Card.Body>
                <span className="press-logo6">
                  <img src={logo6} width="100%" height="100%" alt="l1" />
                </span>
                <Card.Title className="press-title">
                  Johan Feder’s sensational single “Shipwrecked” is the stuff
                  dreams are..
                </Card.Title>

                <a
                  style={{ color: "goldenrod" }}
                  href="https://in.news.yahoo.com/meet-dj-omega-bringing-world-104548996.html"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read More
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Press;
