import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  const [optionValue, changeOptionValue] = useState("Bronze");

  const handleChange = (e) => changeOptionValue(e.target.value);

  return (
    <section>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets10.lottiefiles.com/datafiles/cQiEsPrJCutfedV/data.json"
                background="transparent"
                speed="1"
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h2 style={{ color: "#34343E", fontWeight: "bold" }}>
                Instagram Follower Package{" "}
              </h2>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                Start growing your online brand today and become an authority in
                your space! Our follower growth services will allow you to
                increase your account social proof, exposure and engagement.
                View our most popular packages below!
              </p>
              {/* Dropdown begins*/}

              <h2 style={{ color: "goldenrod" }}>Price on request</h2>
              <select value={optionValue} onChange={handleChange}>
                <option value="Bronze">Bronze Package</option>

                <option value="Silver">Silver Package</option>
                <option value="Gold">Gold Package</option>
              </select>

              {/* Dropdown ends */}

              <div>
                {optionValue === "Gold" && (
                  <div>
                    <ul>
                      <li>
                        Put accounts in growth mode growing them with their
                        country & niche based followers (25k+ monthly)
                      </li>
                      <li>
                        Trend them on hashtags to increase engagement (more
                        likes, more comments, more messages)
                      </li>
                      <li>All comments answered</li>
                      <li>
                        Auto-Direct Message to all new followers (personal
                        message to all new followers’ inbox)
                      </li>
                      <li>
                        All social metrics increased (impressions, reach,
                        profile visits)
                      </li>
                      <li>Reporting on all analytics sent weekly</li>
                      <li>24/7 Customer Service</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Silver" && (
                  <div>
                    <ul>
                      <li>
                        Put accounts in growth mode growing them with their
                        country & niche based followers (15k+ monthly)
                      </li>
                      <li>
                        Trend them on hashtags to increase engagement (more
                        likes, more comments, more messages)
                      </li>
                      <li>All comments answered</li>
                      <li>
                        Auto-Direct Message to all new followers (personal
                        message to all new followers’ inbox)
                      </li>
                      <li>
                        All social metrics increased (impressions, reach,
                        profile visits)
                      </li>
                      <li>24/7 Customer Service</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Bronze" && (
                  <div>
                    <ul>
                      <li>
                        Put accounts in growth mode growing them with their
                        country & niche based followers (5k+ monthly)
                      </li>
                      <li>All comments answered</li>
                      <li>
                        All social metrics increased (impressions, reach,
                        profile visits)
                      </li>
                      <li>24/7 Customer Service</li>
                    </ul>
                  </div>
                )}
              </div>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>How it works?</b>{" "}
              </h3>
            </span>

            <p>
              The process is simple, your account will be inserted into one of
              our small influencer promotions. The “Host Account / Business
              Account” will follow the accounts participating. The Host Account
              is used as a “landing page” for all new followers to find your
              page during the promotion. Our influencer that sponsors the
              campaign will post an image/video on their personal wall directing
              all their followers to check the Host Account and follow everybody
              the Host Account is following. During this process all traffic
              will be directed towards your account and you will attract: new
              followers and occasional post engagement resulting in increased
              exposure.
            </p>
          </MDBCardBody>
        </MDBCard>
      </Container>
    </section>
  );
}

export default OfferBox;
