import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
} from "mdbreact";
import Header2 from "../Subcomponent/header2.js";

import { Container, Nav } from "react-bootstrap";

import pic10 from "../assets/blog10.png";
import pic9 from "../assets/blog9a.png";
import pic8 from "../assets/blog8a.png";
import pic7 from "../assets/blog7a.jpg";
import pic6 from "../assets/blog6a.jpg";
import pic5 from "../assets/blog5a.png";
import pic4 from "../assets/blog4d.jpg";
import pic3 from "../assets/blog3a.png";
import pic2 from "../assets/blog2a.jpg";
import pic1 from "../assets/blog1a.jpg";

import pic11 from "../assets/blog11.jpg";
import pic12 from "../assets/blog12.png";
import pic13 from "../assets/blog13.jpg";
import pic14 from "../assets/blog14.jpg";
import pic15 from "../assets/blog15.png";
import pic16 from "../assets/blog16.jpg";
import pic17 from "../assets/blog17.png";
import pic18 from "../assets/blog18.jpg";
import pic19 from "../assets/blog19.jpg";

import Footer from "../components/footer";

// import {Nav.Link} from 'react-router-dom';

function Blog() {
  return (
    <section id="blog">
      <Header2 />

      <Container>
        <MDBCard className="my-5 px-5 pb-5">
          <MDBCardBody>
            <h2 className="h1-responsive font-weight-bold text-center my-5">
              Recent posts
            </h2>
            <hr className="my-5" />

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic19} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="newspaper" className="pr-2" />
                    Public Figure
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    4 Mistakes That Artists Should Avoid In Building Their Brand
                  </strong>
                </h3>
                <p>
                  The music industry has evolved substantially over the last
                  decade. Leading artists have built their brands rapidly online
                  by harnessing digital channels. From going viral on social
                  media to growing your fan base on Spotify and YouTube...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  04/07/2021
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/19">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon fab icon="instagram" className="pr-2" />
                    Instagram
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    How Instagram Follower Growth Can Turn Your Business Into A
                    Global Brand
                  </strong>
                </h3>
                <p>
                  Every global business starts out as just a seed of an idea.
                  Through careful planning, these seeds grow into industry
                  giants. But it isn’t always about the merit of the idea or the
                  capital behind the creators...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  21/05/2021
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/18">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic18} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic17} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="newspaper" className="pr-2" />
                    Public Figure
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    The Secret-Weapon That Helps Celebrities And Influencers
                    Dominate Instagram
                  </strong>
                </h3>
                <p>
                  Celebrities and influencers usually have social media handles
                  with a massive online following, especially on Instagram.
                  Building a stellar presence on Instagram is no easy feat.
                  While their fame and popularity do make a difference, there is
                  a secret weapon that has...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  06/05/2021
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/17">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="briefcase" className="pr-2" />
                    Social Media Marketing
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    3 Major Trends That Will Shape Social Media Marketing In
                    2021
                  </strong>
                </h3>
                <p>
                  As you plan out your social media strategy for the year, you
                  should account for some emerging trends that will shape the
                  digital marketing industry. Below are three of the top social
                  media marketing trends that experts predict will shape 2021...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  26/04/2021
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/16">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic16} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic15} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="newspaper" className="pr-2" />
                    PR
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    What’s The Difference Between PR And Direct Media
                    Placements?
                  </strong>
                </h3>
                <p>
                  Advertising terms frequently get thrown around as though they
                  are interchangeable. Is “PR” the same as a “direct media
                  placement?” And what do these terms even mean, anyway? Knowing
                  the difference between words like this is the key to becoming
                  savvy and making...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  12/04/2021
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/15">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="tag" className="pr-2" />
                    Brand
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    Here’s The One Thing Common Among Every Global Brand
                  </strong>
                </h3>
                <p>
                  A global brand is one that has achieved the enviable feat of
                  having its brand’s presence reach across the world and make a
                  global impression. Simply hearing the names of brand giants
                  such as Nike and Disney evokes a feeling of trust and
                  familiarity...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  18/03/2021
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/14">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic14} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic13} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="newspaper" className="pr-2" />
                    Company & PRs
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>4 Mistakes Every Company Makes With Their PR</strong>
                </h3>
                <p>
                  Leading companies and brands have several aspects of the
                  business to focus on. While marketing is integral to growth
                  and expansion, managing your companies, PR can have a massive
                  impact when looking at the larger picture. Successfully
                  launching a brand or business means that a business needs to
                  understand the digital landscape....
                </p>
                <p>
                  <strong>Posted on : </strong>
                  27/02/2021
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/13">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon fab icon="instagram" className="pr-2" />
                    Instagram
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    4 Mistakes That Are Hampering Your Instagram Account’s
                    Growth
                  </strong>
                </h3>
                <p>
                  With the advent of Instagram, the business landscape has
                  changed dramatically over the last decade. Various
                  entrepreneurs, influencers, and brands are jumping on
                  Instagram to see rapid growth in their business. From stories
                  and IGTV to reels, Instagram offers a variety of tools to
                  improve your online presence....
                </p>
                <p>
                  <strong>Posted on : </strong>
                  29/01/2021
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/12">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic12} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}
            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic11} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="newspaper" className="pr-2" />
                    Myths on PR
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>Breaking 3 Myths Associated With PR In 2021</strong>
                </h3>
                <p>
                  Marketing your brand in 2021 can be overwhelming. It can feel
                  like you need to be up to date on every online marketing trend
                  to stay ahead of the competition. Each year, new technologies
                  and updates change the landscape of online PR...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  10/01/2021
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/11">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon fab icon="google" className="pr-2" />
                    Knowledge Panel
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    Everything You Need to Know About Google Knowledge Panel
                    Today
                  </strong>
                </h3>
                <p>
                  All of us has come across this block on the top right corner
                  of the search engine result page (SERP). Some know what it’s
                  called while some don’t. For those of you who do not know,
                  this is called the Google Knowledge Panel....
                </p>
                <p>
                  <strong>Posted on : </strong>
                  05/11/2020
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/10">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic10} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic9} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="shopping-cart" className="pr-2" />
                    eCommerce
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>Why Your eCommerce Product Needs PR</strong>
                </h3>
                <p>
                  Every business needs to work with a PR agency in order to
                  reach their audience, sell their products, and meet their
                  financial goals. eCommerce businesses are no different. Just
                  because you are online based, it doesn’t mean you don’t need
                  the expert help to get the...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  08/10/2020
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/9">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="shopping-cart" className="pr-2" />
                    eCommerce
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    Four Ways in Which PR Helps Your eCommerce Business Grow
                  </strong>
                </h3>
                <p>
                  In the era of technology, it was expected to see growth in the
                  eCommerce business. This refers to goods and services that are
                  sold and bought online. And just like any other type of
                  business, your product needs the correct PR to help it grow...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  21/09/2020
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/8">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic8} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic7} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="chart-line" className="pr-2" />
                    Growth
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    5 Amazing PR Tactics to Boost Sales and Growth
                  </strong>
                </h3>
                <p>
                  We are no longer living in the time where the sole marketing
                  technique of businesses was to advertise through print media.
                  The world has progressed beyond expectations, and digital
                  discoveries are thrown every few days..
                </p>
                <p>
                  <strong>Posted on : </strong>
                  30/07/2020
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/7">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="photo-video" className="pr-2" />
                    Media Publication
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    Why does your brand need to be featured in major media
                    publications
                  </strong>
                </h3>
                <p>
                  Gaining media attention is the goal that you fix for a time
                  when your brand matures. You believe in building a firm
                  foundation initially and then work to acquire media publicity.
                  There is nothing wrong with this approach. Except, the
                  expansion and multi-dimensional ...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  19/06/2020
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/6">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic6} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic5} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="hand-holding-usd" className="pr-2" />
                    Return of Investment
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>The Elements of the PR ROI</strong>
                </h3>
                <p>
                  The Return of Investment (ROI) is a way to measure the
                  efficiency of an investment keeping in mind the return of the
                  investment compared to what it cost. Every company, no matter
                  the kind of business they do, is always checking their ROI to
                  see how they are doing, where they should improve, and whether
                  their strategy needs to be changed or not...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  04/04/2020
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/5">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="instagram" className="pr-2" />
                    Instagram verification
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>How to Get Verified on Instagram</strong>
                </h3>
                <p>
                  Only the most influential and popular Instagram accounts will
                  ever see the blue verification tick beside their names, which
                  makes it a sign of status in some way. This also explains why
                  it can be incredibly challenging to get verified on
                  Instagram...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  17/03/2020
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/4">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic4} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic3} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="indigo-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="lightbulb" className="pr-2" />
                    Business Tips
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>
                    5 Important Business Tips for New Entrepreneurs
                  </strong>
                </h3>
                <p>
                  Like every new experience, starting a business comes with its
                  own set of mixed feelings, complex decision making,
                  difficulties and stress but if you get it right from the start
                  it will be very fulfilling at the end...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  08/01/2020
                </p>
                <MDBBtn color="indigo" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/3">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="7">
                <a href="#!" className="pink-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="tag" className="pr-2" />
                    Brand Value
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>How to Brand Yourself</strong>
                </h3>
                <p>
                  Technology has been on the rise for a few decades now, but in
                  recent years, it has become a crucial component in personal
                  success. Nearly every businessperson has made the switch from
                  physical to digital when it comes to selling personal
                  services...
                </p>
                <p>
                  <strong>Posted on : </strong>
                  15/12/2019
                </p>
                <MDBBtn
                  color="pink"
                  size="md"
                  className="mb-lg-0 mb-4 waves-light"
                >
                  <Nav.Link style={{ color: "white" }} href="/2">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic2} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
            </MDBRow>

            <hr className="my-5" />
            {/*--------------------------------------------New Blog----------------------------------------------- */}

            <MDBRow>
              <MDBCol lg="5">
                <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
                  <img className="img-fluid" src={pic1} alt="" />
                  <a href="#!">
                    <MDBMask overlay="white-slight" />
                  </a>
                </MDBView>
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="green-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="briefcase" className="pr-2" />
                    Marketing
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0">
                  <strong>If You’re Using Digital Marketing, Read This.</strong>
                </h3>
                <p>
                  Being a brand or having a brand can be successful only when
                  you manage your public relations with those who have made you
                  or your brand a success i.e. public. A regular communication
                  with the public helps you to know where the brand is going....
                </p>
                <p>
                  <strong>Posted on : </strong>
                  19/11/2019
                </p>
                <MDBBtn color="success" size="md" className="waves-light ">
                  <Nav.Link style={{ color: "white" }} href="/1">
                    Read more
                  </Nav.Link>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </Container>

      <Footer />
    </section>
  );
}

export default Blog;
