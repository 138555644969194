import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  const [optionValue, changeOptionValue] = useState("Tier 1");

  const handleChange = (e) => changeOptionValue(e.target.value);

  return (
    <section>
      <Container style={{ paddingBottom: "70px" }}>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets6.lottiefiles.com/packages/lf20_VyblYx.json"
                background="transparent"
                speed="1"
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                Press Publication Package
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                Whether you’re looking to get seen by thousands or millions,
                Growth Agency can get it done. Below are the media packages to
                get your personal brand or business discovered by millions.
              </p>

              {/* Dropdown begins*/}
              <h2 style={{ color: "goldenrod" }}>Price on request</h2>
              <select value={optionValue} onChange={handleChange}>
                <option value="Tier 1">Cost Effective</option>
                <option value="Tier 2">Domain Authority</option>
                <option value="Tier 3">Artist Package</option>
              </select>

              {/* Dropdown ends */}

              <div>
                {optionValue === "Tier 1" && (
                  <div>
                    <ul>
                      <li>Vents Mag</li>
                      <li>Big Times daily</li>
                      <li>Thrive Global</li>
                      <li>USTimes now</li>
                      <li>Netnews Ledger</li>
                      <li>Disrupt Magazine</li>
                      <li>Yolo Daily</li>
                      <li>Instellite</li>
                      <li>Medium </li>
                      <li>Time Bulletin</li>
                    </ul>

                    <p>
                      Welcome to the Growth Agency Cost Effective Press Package.
                      This package is the most cost-effective press package that
                      Growth Agency currently offers. The goal of this package
                      is to start to filling Google up with good full feature
                      articles about yourself or your company in a short period
                      of time and in a cost-effective way.
                      <br></br>
                      <br></br>Growth Agency is a full-service Agency so our
                      team takes care of the writing of the articles, editing,
                      outreach, pitching, and publishing!
                      <br></br>
                      <br></br>
                      <strong>Timeline:</strong> 2 - 3 Weeks
                    </p>
                  </div>
                )}
                {optionValue === "Tier 2" && (
                  <div>
                    <ul>
                      <li>America Daily Post</li>
                      <li>Influencive</li>
                      <li>Celebmix</li>
                      <li>Yahoo</li>
                      <li>IBTimes Singapore</li>
                      <li>London Daily Post</li>
                      <li>Thive Global</li>
                      <li>American Reporter</li>
                      <li>California Herald</li>
                      <li>Market Watch</li>
                    </ul>
                    <p>
                      Welcome to the Growth Agency Domain Authority Press
                      Package. This package includes 10 highly ranked articles
                      in Google that are chosen largely based on the industry
                      you are in. The goal of this package is to fill Google up
                      with extremely high ranking, full feature articles about
                      yourself or your company in a short period of time and in
                      a cost-effective way.
                      <br></br>
                      <br></br>Growth Agency is a full-service Agency so our
                      team takes care of the writing of the articles, editing,
                      outreach, pitching, and publishing!
                      <br></br>
                      <br></br>
                      <strong>Timeline:</strong> 3 Weeks
                    </p>
                  </div>
                )}
                {optionValue === "Tier 3" && (
                  <div>
                    <ul>
                      <li>Respect Mag </li>
                      <li>The Source Mag </li>
                      <li>Hype Mag </li>
                      <li>Vents Mag </li>
                      <li>Big Time Daily </li>
                      <li>LA Progressive </li>
                      <li>NY Weekly </li>
                      <li>Thrive Global</li>
                      <li>Disrupt Magazine</li>
                      <li>Yahoo</li>
                    </ul>
                    <p>
                      Welcome to the Growth Agency Artist Press Package. This
                      package includes 15 articles that are geared towards the
                      artist/rapper/musician industry. This artist package is
                      perfect to release when a new single is coming out or hype
                      up an album or even just to build a name for yourself on
                      Google.
                      <br></br>
                      <br></br>Growth Agency is a full-service Agency so our
                      team takes care of the writing of the articles, editing,
                      outreach, pitching, and publishing!
                      <br></br>
                      <br></br>
                      <strong>Timeline:</strong> 2 - 3 Weeks
                    </p>
                  </div>
                )}
              </div>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "10px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        style={{
          paddingTop: "60px",
          paddingBottom: "60px",
          backgroundColor: "#f8f8f8",
        }}
      >
        <span style={{ textAlign: "center", padding: "30px" }}>
          <h3>
            <b style={{ color: "#34343E", fontWeight: "bold" }}>
              WHY IS PRESS SO VALUABLE?
            </b>{" "}
          </h3>
          <br></br>
        </span>

        <MDBRow style={{ paddingTop: "40px", textAlign: "center" }}>
          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard">
              <MDBCardBody>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-circle d-flex justify-content-center align-items-center"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src="https://img.icons8.com/plasticine/100/000000/positive-dynamic--v1.png"
                      alt="icon"
                    />
                  </div>
                </div>

                <h5 className="mb-4" style={{ textAlign: "center" }}>
                  #1 - GROW YOUR BRAND
                </h5>

                <p className="grey-text press-cardfont">
                  Press is the BEST way to increase your authority on Google.
                  Articles published by Growth Agency rank at the top of
                  Google’s home page and Google News. There is nothing that
                  closes business deals easier than when people search you up on
                  Google and all the search results are filled with articles
                  about your entire story!
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard">
              <MDBCardBody>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-circle d-flex justify-content-center align-items-center"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src="https://img.icons8.com/dusk/100/000000/search.png"
                      alt="icon"
                    />
                  </div>
                </div>

                <h5 className="mb-4" style={{ textAlign: "center" }}>
                  #2 - NATURAL SEARCH RESULTS
                </h5>

                <p className="grey-text press-cardfont">
                  All of Growth Agency's articles rank at the top of Google
                  search results. These articles can also be viewed in
                  perpetuity by anyone searching up keywords within the
                  articles.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard">
              <MDBCardBody>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-circle d-flex justify-content-center align-items-center"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src="https://img.icons8.com/color/100/000000/instagram-verification-badge.png"
                      alt="icon"
                    />
                  </div>
                </div>

                <h5 className="mb-4" style={{ textAlign: "center" }}>
                  #3 - VERIFICATION ON SOCIAL MEDIA
                </h5>

                <p className="grey-text press-cardfont">
                  Press is how you become "Notable and Newsworthy" on Google
                  which is known to heavily increase your chances of
                  verification on every social media platform.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard">
              <MDBCardBody>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-circle d-flex justify-content-center align-items-center"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src="https://img.icons8.com/doodle/100/000000/media.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <h5 className="mb-4" style={{ textAlign: "center" }}>
                  #4 - SOCIAL MEDIA CONTENT
                </h5>

                <p className="grey-text press-cardfont">
                  After an article is published you can immediately push it
                  across your social media platforms, like your Instagram story.
                  This will undoubtedly get instant recognition from your
                  followers.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard">
              <MDBCardBody>
                <div className="d-flex justify-content-center">
                  <div
                    className="card-circle d-flex justify-content-center align-items-center"
                    style={{ padding: "20px" }}
                  >
                    <img
                      src="https://img.icons8.com/nolan/100/update-tag.png"
                      alt="icon"
                    />
                  </div>
                </div>
                <h5 className="mb-4" style={{ textAlign: "center" }}>
                  #5 - BRAND RECOGNITION
                </h5>

                <p className="grey-text press-cardfont">
                  Once your articles are published you can start adding key
                  sections to your website like "Featured in Yahoo" or "Featured
                  in Forbes," which are big power moves!
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </Container>
    </section>
  );
}

export default OfferBox;
