import React from "react";
import Header2 from "../../Subcomponent/header2.js";
import "./offerStyles.css";

import OfferBox4 from "../../Subcomponent/offerbox4.js";
import Footer from "../footer";

function Offer1() {
  return (
    <section>
      <Header2 />

      <OfferBox4 />

      <Footer />
    </section>
  );
}

export default Offer1;
