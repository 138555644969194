import React from "react";
import { Nav } from "react-bootstrap";
import "../App.css";
// import {Link} from 'react-router-dom';

function Header() {
  return (
    <div>
      <Nav.Link href="blog" className="linked">
        Blog
      </Nav.Link>
    </div>
  );
}

export default Header;
