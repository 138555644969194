import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";

import Header from "../Subcomponent/header2";

import Pic1 from "../assets/blog2a.jpg";
import Footer from "../components/footer";
import { Link } from "react-router-dom";

function Blog2() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  How to Brand Yourself
                </h2>
                <hr className="my-5" />

                <p>
                  <strong>Posted on : </strong>
                  15/12/2019
                </p>

                <img src={Pic1} width="100%" alt="cover" />

                <br></br>
                <br></br>
                <br></br>

                <strong>How to Brand Yourself in the Digital Age</strong>
                <br></br>
                <br></br>
                <p>
                  Technology has been on the rise for a few decades now, but in
                  recent years, it has become a crucial component in personal
                  success. Nearly every businessperson has made the switch from
                  physical to digital when it comes to selling personal
                  services, and it can be overwhelming to take the leap with
                  them. Trying to compete with millions of people doing the same
                  thing as you may seem impossible if you don’t have the right
                  strategy in place.
                </p>
                <p>
                  This is where your personal branding comes in. Sure, there’s
                  plenty of professional competition out there that makes it
                  feel impossible to be seen, but the people who stand out the
                  most had to start somewhere. A key component in selling your
                  services or picking up jobs online is knowing who you are and
                  being confident in what you have to offer.
                </p>

                <strong>1. Treat Your Personal Brand Like a Business</strong>
                <br></br>
                <br></br>

                <p>
                  If you were to start a business selling, say, sandwiches,
                  you’d likely go about it in a completely different way. Maybe
                  you’d hire a company to create branding material for you.
                  You’d think about what makes your brand stand out from other
                  sandwich shops and play up that quality. You’d find the best
                  place for your business to be seen by potential customers and
                  open up shop there. The list goes on.
                </p>

                <p>
                  When you start thinking of yourself—your skills,
                  qualifications, and
                </p>

                <p>
                  and experience—as a real brand, you’ll realize you need to
                  shift your way of thinking. Start with a logo, or at least
                  some visuals (colors, text, style, etc.) that make you stand
                  out as a brand. Then think about selling your services like
                  you would sell anything else. Sometimes it just takes a little
                  distance to get a new perspective.
                </p>

                <strong>2. Take Advantage of What’s Already Out There </strong>
                <br></br>
                <br></br>

                <p>
                  Trying to sell your services from your own personal website
                  with limited experience can feel like being stuck on a small
                  island all by yourself. Start somewhere small with websites
                  like Fiverr and Upwork. These may seem overcrowded with people
                  trying to do the same thing, but if you’re patient, jobs will
                  eventually start rolling in. Make sure to promote yourself on
                  as many platforms as possible, too. Instagram and Facebook are
                  two great tools for reaching potential customers. It might be
                  frustrating handing over a percentage of your earnings for
                  using a service sometimes, but trust me, it’s better than
                  writing cold emails all day long to companies who may never
                  respond.
                </p>

                <strong>3. Find Your Specialty </strong>
                <br></br>
                <br></br>

                <p>
                  When you’re trying to create a professional persona on the
                  internet, you’re going to realize that there will almost
                  always be someone better than you. Don’t try to take on too
                  much at once. Find what you’re really good at and focus on
                  working to become the best at it. Avoid comparing yourself too
                  much to others in the business when creating your own
                  branding. Most people try to make their content fit the mold
                  of what is popular, but a little touch of your own creativity
                  can go a long way.
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog2;
