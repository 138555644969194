import React from "react";
import { Container } from "react-bootstrap";
import "../App.css";
import Modal from "./form.js";

function Cover7() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section id="bookacall" className="contact">
      <Container className="contact-container">
        <h1>
          <strong>Contact Us</strong>
        </h1>

        <p className="cover3-para contact-para">
          If you have questions about anything you saw today, just give us a
          call! One of our Success Catalysts will answer the phone, ready to
          talk to you about your goals & execute on your vision.
        </p>

        <button
          className="btn-hover color-main"
          onClick={() => setModalShow(true)}
        >
          Book a Call
        </button>

        <Modal show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
    </section>
  );
}

export default Cover7;
