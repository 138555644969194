import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section>
      <Container style={{ paddingBottom: "70px" }}>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets4.lottiefiles.com/packages/lf20_gha1v1yp.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                Cold Email
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                This Service will help Marketing Agencies & Freelancers get
                Clients with Personalized Cold Emails, without coming off as a
                Spammer.
              </p>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        style={{
          paddingTop: "60px",
          paddingBottom: "60px",
          backgroundColor: "#f8f8f8",
        }}
      >
        <span style={{ textAlign: "center", padding: "30px" }}>
          <h3>
            <b style={{ color: "#34343E", fontWeight: "bold" }}>
              How does it Work?
            </b>{" "}
          </h3>
          <br></br>
        </span>

        <MDBRow style={{ paddingTop: "40px", textAlign: "center" }}>
          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 1
                  </h5>
                  <p className="grey-text press-cardfont">
                    To keep your main domain safe, I'll set up a secondary
                    domain similar to your main domain.
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 2
                  </h5>

                  <p className="grey-text press-cardfont">
                    To boost deliverability of the emails, I'll set up G suite
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 3
                  </h5>

                  <p className="grey-text press-cardfont">
                    The secondary domain is new, which means it'll have to be
                    warmed up so that the servers know it's not a spam account.
                    To do this, I'll add the email to an engagement group where
                    we send each other emails and unmark them as spam if they
                    ever get marked as spam
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 4
                  </h5>

                  <p className="grey-text press-cardfont">
                    You tell me who your ideal clients are and I'll find them
                    and the emails of their owners. No generic emails like
                    support or info email
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 5
                  </h5>

                  <p className="grey-text press-cardfont">
                    I'll spend 10mins researching each prospect to ensure I send
                    a highly personalized email.
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            style={{ paddingBottom: "40px" }}
            lg="4"
            md="12"
            className="mb-lg-0 mb-4"
          >
            <MDBCard className="press-offerCard-2">
              <MDBCardBody className="check">
                <p>
                  <h5 className="mb-4" style={{ textAlign: "center" }}>
                    Step 6
                  </h5>

                  <p className="grey-text press-cardfont">
                    This is the final step. I'll send the emails for you, and
                    the follow-up sequence. I'll start small to avoid spam
                    filters, then ramp it up after a while.
                  </p>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </Container>
    </section>
  );
}

export default OfferBox;
