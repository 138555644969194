import React from "react";
import { Modal } from "react-bootstrap";
import Calendly from "./calendly";

function MyVerticallyCenteredModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Calendly />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyVerticallyCenteredModal;
