import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation-2">
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_4oaNhu.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                DM Lead Generation
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                Giveaways are great for building numbers at a solid pace.
                However, these users are not following you for your content.
                With our Targeted DM service, these users want to follow you for
                your content and want to buy your products. Who doesn’t want a
                positive ROI and inbound DM leads, without having to do any work
                on cold outreach?
              </p>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>
                  How Our DM Lead Generation Works
                </b>{" "}
              </h3>
            </span>

            <ol>
              <li>
                We target people who follow other large influencers or post on
                specific hashtags in your niche.
              </li>

              <hr className="my-3" />

              <li>
                We compile a list of these users and filter based on the most
                active users and other qualifications of your choice.
              </li>
              <hr className="my-3" />

              <li>
                Our 100's of thousands of accounts send out a targeted DM to
                these users, sharing your profile.
              </li>

              <hr className="my-3" />

              <li>
                New targeted business leads, who care about your content and
                want to buy your products are attracted to your page, bringing
                you increase revenue and profits.
              </li>

              <hr className="my-3" />

              <li>
                Follower campaigns are generally fulfilled within 4-6 days
                depending on our current client load.
              </li>
            </ol>
          </MDBCardBody>
        </MDBCard>
      </Container>
    </section>
  );
}

export default OfferBox;
