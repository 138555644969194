import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../App.css";
import badge from "../assets/badge.png";
import SlideShow from "./slider.js";

function Insta1() {
  var style = {
    backgroundColor: "#34343E",
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  return (
    <section id="ourguarantee" style={style}>
      <Container>
        <Row fluid-sm>
          <Col lg={2} md={2} sm={2} xs={12}>
            <img src={badge} alt="badge" />
          </Col>
          <Col lg={10} md={10} sm={10} xs={12}>
            <h1>
              <b style={{ color: "white" }}>Client’s result</b>{" "}
            </h1>
            <br></br>
            <h3 style={{ color: "white" }}> Real Clients, Real Results</h3>
            <p className="insta1-para">
              Here are some social media growth & Spotify results that we have
              executed in the past.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <SlideShow />
      </Container>
    </section>
  );
}

export default Insta1;
