import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";

import l1 from "../assets/logo/updated/thestatesman.png";
import l2 from "../assets/logo/updated/hindustan-times.png";
import l3 from "../assets/logo/updated/IBTimes.png";
import l4 from "../assets/logo/updated/dh.png";
import l5 from "../assets/logo/updated/neuz.png";
import l6 from "../assets/logo/updated/thrive-global.png";
import l7 from "../assets/logo/updated/25hours.png";
import l8 from "../assets/logo/updated/ustimes.png";
import l9 from "../assets/logo/updated/dailyhunt.png";
import l10 from "../assets/logo/updated/theindiansaga.png";
import l11 from "../assets/logo/updated/ibmw.png";
import l12 from "../assets/logo/updated/influ.png";
import l13 from "../assets/logo/updated/yahoonews.png";
import l14 from "../assets/logo/updated/newsx.png";
import l15 from "../assets/logo/updated/time-bulletin.png";

import DP from "../assets/dpnew.png";

function Cover6() {
  var style = {
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  var para = {
    fontFamily: "Raleway, sans-serif",
    fontSize: "20px",
    color: "#9C9CA8",
    textAlign: "center",
  };

  return (
    <section id="press" style={style}>
      <Container>
        <h2
          style={{ color: "#34343E", textAlign: "center", fontWeight: "bold" }}
        >
          <b>Growth Agency Referral Program</b>{" "}
        </h2>
        <br></br>

        <br></br>
        <p style={para}>
          Earn 10% of the total cost of the referred service or Equivalent
          Growth on your Instagram platform.
        </p>
        <br></br>

        <MDBCard
          className="my-5 px-5 pb-1 text-center about"
          style={{ borderRadius: "25px" }}
        >
          <MDBCardBody>
            <h2 className="h1-responsive font-weight-bold my-5">
              Meet the CEO
            </h2>

            <MDBRow className="text-md-left">
              <MDBCol className="mb-5">
                <MDBCol md="4" lg="4" className="float-left">
                  <img
                    src={DP}
                    className="mx-auto mb-md-0 mb-4 img-fluid"
                    tag="img"
                    width="90%"
                    height="90%"
                    alt="avatar"
                  />
                </MDBCol>
                <MDBCol md="8" lg="8" className="float-right">
                  <h4 className="font-weight-bold mb-3"> Aman Gupta </h4>

                  <p className="grey-text">
                    Aman Gupta is a serial entrepreneur who has helped multiple
                    successful businesses, influencers & artists in the fields
                    of marketing, music, branding and more. With over 6 years of
                    experience in the marketing space. Aman is seeking to change
                    the way personal brands are built. He has been a strategic
                    advisor to many successful startups and brands, and
                    regularly provides consultancy to them in crafting the best
                    business and marketing strategies. His special focus is on
                    leveraging the power of social media to boost businesses
                    exponentially.
                  </p>
                  <a
                    href="http://facebook.com/digitalamangupta"
                    target="__blank"
                    className="p-2 fa-lg fb-ic"
                  >
                    <img
                      src="https://img.icons8.com/color/36/000000/facebook.png"
                      alt="icon"
                    />
                  </a>
                  <a
                    href="http://instagram.com/digitalaman_"
                    target="__blank"
                    className="p-2 fa-lg tw-ic"
                  >
                    <img
                      src="https://img.icons8.com/fluent/36/000000/instagram-new.png"
                      alt="icon"
                    />
                  </a>
                </MDBCol>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </Container>

      <Container>
        <h2
          style={{
            color: "#34343E",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: "30px",
          }}
        >
          <b>Aman Gupta is featured on</b>{" "}
        </h2>
        <br></br>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/38a9cw2" target="__blank">
              <img src={l1} width="100%" height="100%" alt="l1" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3aXAveW" target="__blank">
              <img src={l2} width="100%" height="100%" alt="l2" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3o973GF" target="__blank">
              <img src={l3} width="100%" height="100%" alt="l3" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3b3soxu" target="__blank">
              <img src={l4} width="100%" height="100%" alt="l4" />
            </a>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/38QOXTg" target="__blank">
              <img src={l5} width="100%" height="100%" alt="l1" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/354gQGa" target="__blank">
              <img src={l6} width="100%" height="100%" alt="l2" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3rL2zIm" target="__blank">
              <img src={l7} width="100%" height="100%" alt="l3" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/387btYJ" target="__blank">
              <img src={l8} width="100%" height="100%" alt="l4" />
            </a>
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3bfVD0f" target="__blank">
              <img src={l9} width="100%" height="100%" alt="l1" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3b27heQ" target="__blank">
              <img src={l10} width="100%" height="100%" alt="l2" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/356Cv0J" target="__blank">
              <img src={l11} width="100%" height="100%" alt="l3" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/3hDHRFD" target="__blank">
              <img src={l12} width="100%" height="100%" alt="l4" />
            </a>
          </Col>
        </Row>

        <Row style={{ paddingTop: "70px" }}>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://yhoo.it/3ysV239" target="__blank">
              <img src={l13} width="80%" height="80%" alt="l1" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/35exAKD" target="__blank">
              <img src={l14} width="80%" height="80%" alt="l2" />
            </a>
          </Col>
          <Col lg={3} md={3} sm={6} xs={6}>
            <a href="https://bit.ly/2TXXYGh" target="__blank">
              <img src={l15} width="80%" height="80%" alt="l3" />
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Cover6;
