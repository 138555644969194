import React, { Component } from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import RefundPolicy from "./refundPolicy.js";
import PrivacyPolicy from "./privacyPolicy.js";
import TermsAndCondition from "./termsAndCondition.js";

import Instaoffer1 from "./components/offer/Instaoffer1.js";
import InstaOffer2 from "./components/offer/instaOffer2.js";
import Spotify from "./components/offer/spotify.js";
import Youtube from "./components/offer/youtube.js";
import Press from "./components/offer/press.js";
import Google from "./components/offer/google.js";
import DMleadgeneration from "./components/offer/DMLead.js";
import Coldemail from "./components/offer/coldemail.js";

import Home from "./Home.js";
import Blog from "./blogs/Blog.js";
import Blog1 from "./blogs/blog1.js";
import Blog2 from "./blogs/blog2.js";
import Blog3 from "./blogs/blog3.js";
import Blog4 from "./blogs/blog4.js";
import Blog5 from "./blogs/blog5.js";
import Blog6 from "./blogs/blog6.js";
import Blog7 from "./blogs/blog7.js";
import Blog8 from "./blogs/blog8.js";
import Blog9 from "./blogs/blog9.js";
import Blog10 from "./blogs/blog10.js";
import Blog11 from "./blogs/blog11.js";
import Blog12 from "./blogs/blog12.js";
import Blog13 from "./blogs/blog13.js";
import Blog14 from "./blogs/blog14.js";
import Blog15 from "./blogs/blog15.js";
import Blog16 from "./blogs/blog16.js";
import Blog17 from "./blogs/blog17.js";
import Blog18 from "./blogs/blog18.js";
import Blog19 from "./blogs/blog19.js";

import NotFound from "./error";

import ScrollToTop from "./scroll";

// import { createBrowserHistory } from "history";

// export const appHistory = createBrowserHistory();

class App extends Component {
  render() {
    // appHistory.push('/');
    // appHistory.goBack();

    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          {/* <Route exact path="/refundpolicy">
            <RefundPolicy />
          </Route>
          <Route exact path="/termsandcondition">
            <TermsAndCondition />
          </Route> */}
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/instagram-follower">
            <Instaoffer1 />
          </Route>
          <Route exact path="/instagram-engagement">
            <InstaOffer2 />
          </Route>
          {/* <Route exact path="/spotify">
            <Spotify />
          </Route> */}
          <Route exact path="/youtube">
            <Youtube />
          </Route>
          <Route exact path="/press-publication">
            <Press />
          </Route>
          <Route exact path="/google-knowledge-panel">
            <Google />
          </Route>

          <Route exact path="/DMleadgeneration">
            <DMleadgeneration />
          </Route>
          <Route exact path="/coldemail">
            <Coldemail />
          </Route>

          <Route exact path="/1">
            <Blog1 />
          </Route>
          <Route exact path="/2">
            <Blog2 />
          </Route>
          <Route exact path="/3">
            <Blog3 />
          </Route>
          <Route exact path="/4">
            <Blog4 />
          </Route>
          <Route exact path="/5">
            <Blog5 />
          </Route>
          <Route exact path="/6">
            <Blog6 />
          </Route>
          <Route exact path="/7">
            <Blog7 />
          </Route>
          <Route exact path="/8">
            <Blog8 />
          </Route>
          <Route exact path="/9">
            <Blog9 />
          </Route>
          <Route exact path="/10">
            <Blog10 />
          </Route>

          <Route exact path="/11">
            <Blog11 />
          </Route>

          <Route exact path="/12">
            <Blog12 />
          </Route>

          <Route exact path="/13">
            <Blog13 />
          </Route>

          <Route exact path="/14">
            <Blog14 />
          </Route>

          <Route exact path="/15">
            <Blog15 />
          </Route>

          <Route exact path="/16">
            <Blog16 />
          </Route>

          <Route exact path="/17">
            <Blog17 />
          </Route>

          <Route exact path="/18">
            <Blog18 />
          </Route>

          <Route exact path="/19">
            <Blog19 />
          </Route>

          <Route path="/">
            <Home />
          </Route>

          <Route path="" Component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
