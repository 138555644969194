import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Modal from "../components/form.js";
import "../components/offer/offerStyles.css";

import { MDBCard, MDBCardBody } from "mdbreact";

function OfferBox() {
  const [modalShow, setModalShow] = React.useState(false);

  const [optionValue, changeOptionValue] = useState("Bronze");

  const handleChange = (e) => changeOptionValue(e.target.value);

  return (
    <section>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          {/* Graphic part */}
          <Col lg={4}>
            <div className="animation">
              <lottie-player
                src="https://assets10.lottiefiles.com/private_files/lf30_cwyafad8.json"
                background="transparent"
                speed="1"
                autoplay
              ></lottie-player>
            </div>
          </Col>
          {/* Offer part */}
          <Col lg={8}>
            <div className="OfferBox">
              <h3 style={{ color: "#34343E", fontWeight: "bold" }}>
                Youtube Package
              </h3>
              <b>Growth Agency</b>
              <br></br>
              <br></br>

              <p>
                Do you want to have more views on your YouTube videos? Buying
                YouTube views is a real and effective technique that has helped
                thousands of YouTube creators achieve an incredible level of
                popularity in a short period of time. When you are a starter,
                you don’t directly get the rewards for the hard work you did for
                the video’s. It’s very difficult to convince the YouTube
                algorithm to prefer your videos and channel rather than your
                niche competitors. But we can make it easier for you.
              </p>
              {/* Dropdown begins*/}
              <h2 style={{ color: "goldenrod" }}>Price on request</h2>
              <select value={optionValue} onChange={handleChange}>
                <option value="Bronze">Bronze Package</option>
                <option value="Silver">Silver Package</option>
                <option value="Gold">Gold Package</option>
                <option value="Platinum">Platinum Package</option>
                <option value="Diamond">Diamond Package</option>
              </select>

              {/* Dropdown ends */}

              <div>
                {optionValue === "Diamond" && (
                  <div>
                    <ul>
                      <li>1M - 1.3M Views in one video</li>
                      <li>5k - 7k Subscribers</li>
                      <li>15000 -20000 Llikes on video</li>
                      <li>2000 - 4000 Comments (depending on the content)</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Platinum" && (
                  <div>
                    <ul>
                      <li>500k - 600k Views in one video</li>
                      <li>3k- 4k Subscribers</li>
                      <li>7000 - 10000 Likes on video</li>
                      <li>1000 - 1500 Comments (depending on the content)</li>
                    </ul>
                  </div>
                )}
                {optionValue === "Gold" && (
                  <div>
                    <ul>
                      <li>150k - 200k Views in one video</li>
                      <li>2k - 3k Subscribers</li>
                      <li>2000 - 4000 Likes on video</li>
                      <li>
                        300 - 600 Comments on video (depending on the content)
                      </li>
                    </ul>
                  </div>
                )}

                {optionValue === "Silver" && (
                  <div>
                    <ul>
                      <li>50 -100k Views in one video</li>
                      <li>1k- 1.5k Subscribers</li>
                      <li>1000 - 1500 Likes on video</li>
                      <li>
                        100 - 200 Comments on video (depending on the content)
                      </li>
                    </ul>
                  </div>
                )}

                {optionValue === "Bronze" && (
                  <div>
                    <ul>
                      <li>20k -30k Views in one video</li>
                      <li>500 - 800 Subscribers</li>
                      <li>500 - 700 Likes on video</li>
                      <li>
                        50 - 80 Comments on video (depending on the content)
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              {/* Call Button */}
              <div style={{ textAlign: "center", padding: "40px" }}>
                <button
                  className="btn-hover color-main"
                  onClick={() => setModalShow(true)}
                >
                  Book a Call
                </button>
                <Modal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>
                  GET REAL YOUTUBE VIEWS FAST, EASY AND SAFE
                </b>{" "}
              </h3>
            </span>

            <p>
              These viewers are from real traffic that is directed to your
              video. The viewers are 100% real, so we unfortunately have no way
              to control how long they stay on your video, so we can’t offer
              retention. Since these are from real people who watch your video,
              you can expect comments, subscribers, likes and dislikes.
            </p>
          </MDBCardBody>
        </MDBCard>
      </Container>

      <Container style={{ paddingBottom: "70px" }}>
        <MDBCard>
          <MDBCardBody>
            <span style={{ textAlign: "center", padding: "30px" }}>
              <h3>
                <b style={{ color: "#34343E" }}>
                  WHY INVEST IN YOUTUBE VIEWS?{" "}
                </b>{" "}
              </h3>
            </span>

            <p>
              Your video’s success will largely depend on the amount of social
              proof or engagement it has gained. If it has a lot of views, other
              viewers will be more curious and encouraged to check it out. If
              your video has a low number of views, people will be less
              interested to watch it.
            </p>
          </MDBCardBody>
        </MDBCard>
      </Container>
    </section>
  );
}

export default OfferBox;
