import React, { Component } from "react";
import Slider from "react-slick";

import slide1 from "../assets/slide1.jpg";
import slide2 from "../assets/slide2.jpg";
import slide3 from "../assets/slide3.jpg";
import slide4 from "../assets/slide4.jpg";
import slide5 from "../assets/slide5.jpg";
import slide6 from "../assets/slide6.jpg";
import slide7 from "../assets/slide7a.jpg";
import slide8 from "../assets/slide8a.jpg";
import slide9 from "../assets/slide9a.jpg";
import slide10 from "../assets/slide10a.jpg";
import slide11 from "../assets/slide11.jpg";
import slide12 from "../assets/slide12.jpg";
import slide13 from "../assets/slide13.jpg";
import slide14 from "../assets/slide14.jpg";

export default class SlideShow extends Component {
  render() {
    var settings_2 = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
          },
        },
      ],
    };

    var style = {
      padding: "30px",
    };

    return (
      <div style={style}>
        <Slider {...settings_2}>
          <div className="slide-image">
            <img src={slide1} alt="slide1" />
          </div>
          <div className="slide-image">
            <img src={slide2} alt="slide2" />
          </div>
          <div className="slide-image">
            <img src={slide3} alt="slide3" />
          </div>
          <div className="slide-image">
            <img src={slide4} alt="slide4" />
          </div>
          <div className="slide-image">
            <img src={slide5} alt="slide5" />
          </div>

          <div className="slide-image">
            <img src={slide8} alt="slide8" />
          </div>
          <div className="slide-image">
            <img src={slide9} alt="slide9" />
          </div>
          <div className="slide-image">
            <img src={slide10} alt="slide10" />
          </div>
          <div className="slide-image">
            <img src={slide11} alt="slide11" />
          </div>
          <div className="slide-image">
            <img src={slide12} alt="slide12" />
          </div>
          <div className="slide-image">
            <img src={slide13} alt="slide13" />
          </div>
          <div className="slide-image">
            <img src={slide14} alt="slide14" />
          </div>
          <div className="slide-image">
            <img src={slide6} alt="slide6" />
          </div>
          <div className="slide-image">
            <img src={slide7} alt="slide7" />
          </div>
        </Slider>
      </div>
    );
  }
}
