import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Header from "../Subcomponent/header2";

import Pic1 from "../assets/blog5a.png";
import Pic2 from "../assets/blog5b.jpg";
import Pic3 from "../assets/blog5c.png";

import Footer from "../components/footer";

import { Link } from "react-router-dom";

function Blog5() {
  return (
    <section>
      <Header />
      <MDBContainer>
        <MDBRow>
          <MDBCol lg={9}>
            <MDBCard className="my-5 px-5 pb-5">
              <MDBCardBody>
                <h2 className="h2-responsive text-center my-5">
                  The Elements of the PR ROI
                </h2>
                <hr className="my-5" />
                <p>
                  <strong>Posted on : </strong>
                  04/04/2020
                </p>

                <img src={Pic1} width="100%" alt="cover" />

                <br></br>
                <br></br>
                <br></br>

                <p>
                  The Return of Investment (ROI) is a way to measure the
                  efficiency of an investment keeping in mind the return of the
                  investment compared to what it cost. Every company, no matter
                  the kind of business they do, is always checking their ROI to
                  see how they are doing, where they should improve, and whether
                  their strategy needs to be changed or not.
                </p>

                <div style={{ padding: "50px", textAlign: "center" }}>
                  <img src={Pic2} width="80%" alt="cover" />
                </div>

                <p>
                  When it comes to PR activities, there are two ways in which
                  you can measure your ROI:
                  <br></br>
                  <ol>
                    <li>
                      With every monetary gain you’ve had from PR activities
                    </li>
                    <li>
                      Any earned media that helps build credibility and
                      reputation.
                    </li>
                  </ol>
                  <img src={Pic3} width="100%" alt="cover" />
                  <br></br>
                  <br></br>
                  By tracking these two elements, you will know the kind of
                  improvements you should be making, or which good strategies
                  have been working for your company. But these don’t just work
                  on their own. There are a few other aspects of your business
                  that you need to take into consideration.
                </p>
                <ol style={{ listStyleType: "circle" }}>
                  <li>
                    Page Views: when a page on your site is clicked on a Google
                    ad. This depends on the market of your startup and the type
                    of content it produces. What matters is that the views
                    increase month to month.
                  </li>
                  <li>
                    Bounce Rate: the ratio of single page sessions on your site
                    to multiple page sessions. It depends on the purpose of a
                    page and can be affected by third-party plugins, incorrect
                    tracking tags, and poorly implemented analytics code.
                  </li>
                  <li>
                    Audience: always keep your target audience in mind because
                    it will allow you to develop the desired consumer profile.
                    You will need this information for the content you want to
                    create since it is important to know the most popular user
                    demographics
                  </li>
                  <li>
                    Domain Authority Score: it is a comparative representation
                    of how the content of your site will be ranked on Google
                    SERPS. It basically shows, from 1 to 100, how high Google
                    will value your content
                  </li>
                </ol>
                <br></br>
                <br></br>

                <p>
                  Knowing how your PR ROI works will help you determine a more
                  functioning strategy. You need to understand your earnings to
                  make sure you aren’t losing money and your budget is up to
                  date. These are a few of the elements that will guide you
                  through your financial process since it is very important to
                  keep in mind how your website is working, how many view it is
                  receiving, and how the audience you are trying to reach will
                  react to your content. After all, the way you are viewed will
                  establish your credibility leading to a higher monetary income
                  and better media relations
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="3" className="my-5 px-5 pb-5">
            <h5 style={{ fontWeight: "bold" }}>Recent Posts</h5>
            <div style={{ listStyle: "none" }}>
              <Link to="/19">
                4 Mistakes That Artists Should Avoid In Building Their Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/18">
                How Instagram Follower Growth Can Turn Your Business Into A
                Global Brand
              </Link>
              <br></br>
              <br></br>

              <Link to="/17">
                The Secret-Weapon That Helps Celebrities And Influencers
                Dominate Instagram
              </Link>
              <br></br>
              <br></br>

              <Link to="/16">
                3 Major Trends That Will Shape Social Media Marketing In 2021
              </Link>
              <br></br>
              <br></br>

              <Link to="/15">
                {" "}
                What’s The Difference Between PR And Direct Media Placements?
              </Link>
              <br></br>
              <br></br>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </section>
  );
}

export default Blog5;
