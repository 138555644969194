import React from "react";
import "./App.css";

import Header from "./components/header.js";
import Banner from "./components/banner.js";
import Cover2 from "./components/cover2.js";
import Insta from "./components/insta1.js";
import Details from "./components/details.js";

import Cover6 from "./components/cover6.js";
import Press from "./components/press.js";
import Cover7 from "./components/cover7.js";
import Cover8 from "./components/cover8.js";

import MessengerCustomerChat from "react-messenger-customer-chat";
import Footer from "./components/footer";
import Offer from "./components/offer/offer";
import Testimonial from "./components/testimonial";
import WarningSection from "./components/warningSection";

function Home() {
  return (
    <div>
      <div className="top">
        <Header />
        <Banner />
      </div>

      <div className="basic">
        <div style={{ paddingBottom: "100px" }}>
          <Cover2 />
        </div>
        <Insta />
        <Details />
        <Offer />
        <Cover6 />
        <Press />
        <WarningSection />
        <Testimonial />

        <Cover8 />

        <Cover7 />
      </div>
      <MessengerCustomerChat pageId="103102681127286" appId="732009220680259" />
      <Footer />
    </div>
  );
}

export default Home;
