import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../App.css";
import logo from "../assets/logoP.png";
import Modal from "./form.js";

function Banner() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className="logo1">
              <img src={logo} width="300px" height="300px" alt="" />
            </div>

            <div className="logo2">
              <img src={logo} width="250px" height="250px" alt="" />
            </div>
          </Col>

          <Col xs={12} md={12} lg={12} className="banner-text">
            The #1 Agency for Instagram Growth & Engagements, Youtube and
            Spotify Marketing, Press & Publications.
            <br></br>
            <br></br>
            <button
              className="btn-hover color-main"
              onClick={() => setModalShow(true)}
            >
              Book a Call
            </button>
            <Modal show={modalShow} onHide={() => setModalShow(false)} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Banner;
