import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../App.css";
import { Link } from "react-router-dom";

function Header() {
  var custom = {
    color: "white",
    display: "flex",
  };
  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle id="nav-icon1" aria-controls="basic-navbar-nav">
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar bg="dark">
              <Navbar.Brand>
                <Link to="/" className="linked">
                  𝔾𝕣𝕠𝕨𝕥𝕙 𝔸𝕘𝕖𝕟𝕔𝕪
                </Link>
              </Navbar.Brand>
            </Navbar>
            <br />
            <Nav className="ml-auto nav-link">
              <Nav.Link style={custom}>
                <Link to="/" className="linked">
                  {" "}
                  Home{" "}
                </Link>{" "}
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>

              <NavDropdown title="Services" id="collasible-nav-dropdown">
                <NavDropdown.Item>
                  {" "}
                  <Link to="/instagram-follower">
                    Instagram Follower Package
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link to="/instagram-engagement">
                    Instagram Engagement Package
                  </Link>
                </NavDropdown.Item>
                {/* <NavDropdown.Item>
                  {" "}
                  <Link to="/spotify">Spotify Package</Link>
                </NavDropdown.Item> */}
                <NavDropdown.Item>
                  {" "}
                  <Link to="/youtube">Youtube Package</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link to="/press-publication">Press Publication Package</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link to="/google-knowledge-panel">
                    Google Knowledge Panel Package
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link to="/DMleadgeneration">DM Lead Generation</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link to="/coldemail">Cold Email</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link style={custom}>
                <Link to="/blog" className="linked">
                  {" "}
                  Blog{" "}
                </Link>
                &nbsp;&nbsp;&nbsp;
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
